/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

/* .App {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.Wheel {
  width: 400px;
  padding-top: 10em;
  margin-right: 10em;
  padding-right: 5em;
  overflow: hidden;
}

.Wheel canvas {
  height: 400px;
}

.Input {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5em;
}

.Input input {
  border: 2px solid gray;
  outline: none;
  padding: 0.5em;
  font-size: 1em;
  border-radius: 0.3em;
}

.clip-container {
  display: flex;
  align-items: center;
  justify-content: end;
}

.clipboard-btn {
  height: 3em;
  width: 10em;
  margin-top: 1em;
  margin-right: 1em;
  font-size: 0.8em;
  background-color: #1f1f1f;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 0.2em;
  transition: all ease 0.5s;
}

.clipboard-btn:hover {
  background-color: #000000;
  transform: scale(1.05, 1.05);
}

.Input button {
  height: 2em;
  width: 4em;
  border: none;
  background-color: rgb(47, 47, 47);
  color: white;
  border-radius: 0.3em;
  cursor: pointer;
  margin-left: 2em;
  transition: all ease 0.5s;
}

.Input button:hover {
  background-color: rgb(25, 25, 25);
  transform: scale(1.1, 1.1) translateY(-5px);
}

.List {
  padding: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all ease 0.5s;
}

.ListItem {
  width: 30em;
  padding: 0.5em 2em;
  margin: 0.5em 0em;
  border-radius: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: antiquewhite;
}

.rm-btn {
  height: 2em;
  width: 6em;
  letter-spacing: 1px;
  border: none;
  background-color: rgb(47, 47, 47);
  color: white;
  border-radius: 0.3em;
  cursor: pointer;
  margin-left: 2em;
  transition: all ease 0.3s;
}

.rm-btn:hover {
  background-color: rgb(25, 25, 25);
  transform: scale(1.03, 1.03) translateY(-3px);
}

@media only screen and (max-width: 767px) {
  .ListItem {
    width: 20em;
  }

  .App {
    flex-direction: column;
  }

  .Wheel {
    width: 300px;
    padding-top: 1em;
    padding-left: 2em;
    margin-left: 9em;
  }

  .Wheel canvas {
    height: 350px;
  }
}

/* Footer CSS */

.footer-sec {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.footer-sec .footer-icons a {
  display: inline-block;
  text-decoration: none;
  color: #1f1f1f;
  margin-right: 10px;
}
.footer-sec .footer-icons a i {
  display: block;
  height: 60px;
  width: 60px;
  font-size: 24px;
  color: #1f1f1f;
  line-height: 64px;
  text-align: center;
  border-radius: 40px;
  background: transparent;
  -webkit-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}

.footer-sec .footer-icons a i.la-facebook-f:hover,
.footer-sec .footer-icons a i.la-facebook-f:focus {
  color: #ffffff;
  -webkit-box-shadow: 0 0 15px 30px #3d558f inset;
  box-shadow: 0 0 15px 30px #3d558f inset;
}
.footer-sec .footer-icons a i.la-twitter:hover,
.footer-sec .footer-icons a i.la-twitter:focus {
  color: #ffffff;
  -webkit-box-shadow: 0 0 15px 30px #33c9dd inset;
  box-shadow: 0 0 15px 30px #33c9dd inset;
}
.footer-sec .footer-icons a i.la-google:hover,
.footer-sec .footer-icons a i.la-google:focus {
  color: #ffffff;
  -webkit-box-shadow: 0 0 15px 30px #d74937 inset;
  box-shadow: 0 0 15px 30px #d74937 inset;
}
.footer-sec .footer-icons a i.la-linkedin-in:hover,
.footer-sec .footer-icons a i.la-linkedin-in:focus {
  color: #ffffff;
  -webkit-box-shadow: 0 0 15px 30px #0474bc inset;
  box-shadow: 0 0 15px 30px #0474bc inset;
}

.footer-sec .footer-icons a i.la-instagram:hover,
.footer-sec .footer-icons a i.la-instagram:focus {
  color: #ffffff;
  background-image: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  /*-webkit-box-shadow: 0 0 15px 30px #fd5949 inset;*/
  /*box-shadow: 0 0 15px 30px #fd5949 inset;*/
  animation: 1s instaRound;
}
.footer-sec .footer-icons a i.la-envelope:hover,
.footer-sec .footer-icons a i.la-envelope:focus {
  color: #ffffff;
  -webkit-box-shadow: 0 0 15px 30px #d45049 inset;
  box-shadow: 0 0 15px 30px #d45049 inset;
}

@-webkit-keyframes instaRound {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes instaRound {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.intro-banner-vdo-play-btn .ripple:nth-child(2) {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}
.intro-banner-vdo-play-btn .ripple:nth-child(3) {
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}
